/** @jsx jsx */
import { jsx } from 'theme-ui'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      sx={{
        textAlign: 'center',
        minHeight: '50vh',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
      }}
    >
      <h1>NOT FOUND</h1>
      <p>
        Sorry friend, it looks like the page you're looking for doesn't exist…
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
